// Imports
// ------------
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------------
import { Jacket, BaseImage } from './styles';

// Component
// ------------
const SingleImageMobile = ({ ar, image, alt }) => {
    return (
        <Jacket aspectRatio={ar}>
            <BaseImage>
                <GatsbyImage image={image} alt={alt} />
            </BaseImage>
        </Jacket>
    );
}

export default React.memo(SingleImageMobile);