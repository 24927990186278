// Imports
// ------------
import styled, { css, keyframes } from 'styled-components';
import { breakup, H3 } from '@tackl';

// Exports
// ------------
const fillAnim = (props) => keyframes`
    to {
        width: ${props.isHolding ? `100%` : `0%`};
    }
`;

const sharedJacket = css`
    position: relative;

    display: block;
    height: 40vh;

    ${breakup.large` height: 80vh; `}

    > div.holder { height: 100%; }
`;

export const Jacket = styled.div(props => css`
    ${sharedJacket}
`);


export const Image = styled.div(props => css`
    position: relative;
    height: 100%;
    pointer-events: none;

    .gatsby-image-wrapper, img {
        transition: all 1s ${props.theme.bezzy} !important;
        height: 100%;
    }
`);


export const Subtext = styled.div(props => css`
    position: absolute;
    z-index: 2;
    top: -3.6rem; left: 0; right: 0;
    width: 100%;
    pointer-events: none;

    span {
        color: ${props.theme.bc3};
    }
`);

export const Title = styled(H3)(props => css`
    position: absolute;
    z-index: 3;
    top: -2.4rem; left: 0; right: 0;

    width: 90%;
    padding-left: 2.4rem;

    ${breakup.medium`
        padding-left: 6rem;
        width: calc(8.33vw * 4);
        left: calc(8.33vw * 1);
    `}

    color: ${props.theme.bc1};

    span {
        font-size: 1.2rem;
        font-weight: bold;
        color: ${props.theme.white};
    }
`);

export const Next = styled.div(props => css`
    position: absolute;
    z-index: 4;
    top: -4.8rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding-left: 2.4rem;

    ${breakup.medium`
        padding-left: 6rem;
        width: calc(8.33vw * 10);
        left: calc(8.33vw * 1);
    `}

    span {
        display: flex;
        white-space: nowrap;
        padding-right: 2.4rem;

        font-family: ${props.theme.body};
        font-size: 1.4rem;
        text-transform: uppercase;
        color: ${props.theme.bc2};
    }
`);

export const Line = styled.div(props => css`
    position: relative;
    display: flex;

    width: 100%;
    margin-right: 2.4rem;
    height: 1px;
    background: ${props.theme.bc2};

    ${breakup.medium` margin-right: 0; `}

    &:before {
        content: '';
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;

        background: white;
        height: 1px;
        width: 0%;

        animation: ${fillAnim} 2s forwards;
        animation-play-state: ${props.isHolding ? `running` : `paused`};
    }
`);