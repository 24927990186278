// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';
import { Row } from '@waffl';

// Exports
// ------------
export const Jacket = styled(Section)((props) => css`
    transition: all 1.2s ${props.theme.bezzy};
    transform: translateY(${props.isVisible ? `0` : `30vh`});
`);

export const Sweater = styled(Row)((props) => css`
    display: flex;

    ${breakup.medium` padding-bottom: 3.6rem; `}

    ${!props.stacked && css`
        ${breakup.medium`
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: auto;
            gap: 3.6rem;
            grid-template-areas:
                " . . . one one one one one two two two . "
                " . . three three three three three three two two two . "
                " . . . . four four four five five five five . ";
        `}
    `}
`);

export const Photo = styled.div(props => css`
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 2.4rem;
    margin-bottom: 3.6rem;

    ${breakup.medium`
        margin: 0 calc(4.2rem + 1.8rem);
        margin-bottom: 3.6rem;
    `}
    
    ${!props.stacked && css`
        ${breakup.medium`
            margin: 0 3.6rem;
            grid-area: ${props.gridArea};

            &:not(:last-child) {
                top: ${props.gridArea === 'two' ? 12 : 0}vh;

                ${breakup.xlarge`
                    top: ${props.gridArea === 'two' ? 25 : 0}vh;
                `}
            }
        `}
    `}
`);