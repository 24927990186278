// Imports
// ------------
import React, { useState, useEffect } from 'react';
import GalleryCard from './Card';
import Icon from '@icons';
import useSound from 'use-sound';
import Click from '@sounds/click.mp3';
import SwiperCore, { Navigation, FreeMode, Controller } from 'swiper';
import gsap from 'gsap';
import Scrollbar from 'smooth-scrollbar';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Swiper, SwiperSlide } from 'swiper/react';
import { galleryModal } from '@states';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';

// Styles
// ------------
import { Jacket, Next, Prev, TopNumbers, TotalNumbers, Close } from './styles';

// Initialise
// ------
SwiperCore.use([Navigation, FreeMode, Controller]);
gsap.registerPlugin(ScrollTrigger);

// Component
// ------------
const GalleryCarousel = ({ data }) => {
    const [swiperRef, setSwiperRef] = useState(null);
    const [controlledNumbers, setControlledNumbers] = useState(null);

    const [play] = useSound(Click, { volume: 1 });

    const handleClose = action(() => {
        galleryModal.isActive = !galleryModal.isActive;
        galleryModal.activeSlide = null;

        play();
    });

    useEffect(() => {
        if(galleryModal.activeSlide) {
            swiperRef.slideTo(galleryModal.activeSlide)
        }
    });


    // NOTE • Handle hash links
    let bodyScrollBar;

    useEffect(() => {
        const scroller = document.querySelector('#scroller');
        bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

        ScrollTrigger.scrollerProxy(".scroll-content", {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });
    
        bodyScrollBar.addListener(ScrollTrigger.update);
    });

    const handleHash = (e) => {
        let link = document.querySelector(`#image-` + e.activeIndex);
        let scrollHere = link.offsetTop;

        play();

        bodyScrollBar.scrollTo(0, scrollHere, 1000);
    }

    return (
        <Jacket isActive={galleryModal.isActive}>
            <Close onClick={handleClose} isActive={galleryModal.isActive} className="ch">
                <Icon type="close" />
            </Close>

            <Swiper
                initialSlide={0}
                spaceBetween={0}
                loop={true}
                speed={1000}

                slidesPerView={1}
                centeredSlides={true}
                
                draggable={false}
                grabCursor={false}
                allowTouchMove={false}

                navigation={{
                    nextEl: '.swiperNext',
                    prevEl: '.swiperPrev',
                }}

                modules={[Controller]}
                controller={{ control: controlledNumbers }}
                onSwiper={setSwiperRef}
            >
                {data.map((image, i) => (
                    <SwiperSlide key={i + 1}>
                        <GalleryCard image={image} />
                    </SwiperSlide>
                ))}

                <Next className="swiperNext" onClick={(e) => handleHash(swiperRef)} />
                <Prev className="swiperPrev" onClick={(e) => handleHash(swiperRef)} />
            </Swiper>

            <TopNumbers isActive={galleryModal.isActive}>
                <Swiper
                    initialSlide={0}
                    direction={"vertical"}
                    speed={500}
                    slidesPerView={1}
                    loop={true}
                    modules={[Controller]}
                    onSwiper={setControlledNumbers}
                >
                    {data.map((node, i) => (
                        <SwiperSlide key={i + 1}>{i < 9 && `0`}{i + 1}</SwiperSlide>
                    ))}
                </Swiper>
            </TopNumbers>

            <TotalNumbers isActive={galleryModal.isActive}>/{data.length}</TotalNumbers>
        </Jacket>
    );
}

export default observer(GalleryCarousel);