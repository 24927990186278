// Imports
// ------------
import styled, { css } from 'styled-components';
import { Section } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)((props) => css`
    ${props.theme.vh};
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 9999999;

    pointer-events: ${props.isActive ? `all` : `none`};

    display: flex;
    align-items: center;

    &:before {
        transition: all .6s ${props.theme.bezzy};
        transition-delay: ${props.isActive ? `0s` : `0.8s`};
        content: '';
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;

        background: ${props.theme.black};
        opacity: ${props.isActive ? `.95` : `0`};
    }

    .swiper {
        transition: clip-path 1.2s ${props.theme.bezzy};
        clip-path: inset(${props.isActive ? `0% 0% 0% 0%` : `50% 0% 50% 0%`});
        transition-delay: ${props.isActive ? `.8s` : `0s`};

        position: absolute;
        z-index: 12;
        
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;

        &-wrapper {
            display: flex;
            align-items: center;
        }

        &-slide {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`);

export const Next = styled.div`
    position: absolute;
    top: 0; right: 0; bottom: 0;
    z-index: 999999;
    width: 50%;
`;

export const Prev = styled.div`
    position: absolute;
    top: 0; left: 0; bottom: 0;
    z-index: 999999;
    width: 50%;
`;


export const TopNumbers = styled.div((props) => css`
    position: absolute;
    top: -3.6rem; left: 0;
    width: 25vw;
    height: 18vw;
    z-index: 0;

    pointer-events: none;

    font-family: ${props.theme.fancy};
    font-size: 18vw;
    color: ${props.theme.bc1};

    .swiper {
        transition: clip-path 1s ${props => props.theme.bezzy};
        transition-delay: .2s;
        clip-path: inset(${(props) => props.isActive ? `0% 0% 0% 0%` : `50% 0% 50% 0%`});

        &-slide {
            align-items: flex-start;
            justify-content: flex-start;
        }
    }
`);

export const TotalNumbers = styled.div((props) => css`
    transition: clip-path 1s ${props => props.theme.bezzy};
    transition-delay: .2s;
    clip-path: inset(${(props) => props.isActive ? `0% 0% 0% 0%` : `50% 0% 50% 0%`});

    position: absolute;
    bottom: -6rem; right: 0;
    z-index: 0;

    pointer-events: none;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    font-family: ${props.theme.fancy};
    font-size: 18vw;
    color: ${props.theme.bc2};

    opacity: .12;
`);


export const Close = styled.div((props) => css`
    transition: all 1s ${props.theme.bezzy};
    transition-delay: ${props.isActive ? `.8s` : `0s`};

    position: absolute;
    z-index: 999999;
    top: 0; left: 50%;
    transform: translateX(-50%) translateY(${props.isActive ? `6vh` : `-120%`});

    width: 4.8rem;
    height: 4.8rem;

    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        transition: all .8s ${props.theme.bezzy};
        content: '';
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        z-index: -1;

        background: ${props.theme.bc2};
        border-radius: 100%;

        opacity: .12;
    }

    svg { transition: all .8s ${props.theme.bezzy}; }

    &:hover {
        &:before {
            opacity: 1;
        }

        svg {
            fill: ${props.theme.bc1};
        }
    }
`);