// Imports
// ------------
import React from 'react';
import DesktopImage from './DesktopImage';
import MobileImage from './MobileImage';
import useSound from 'use-sound';
import Click from '@sounds/click.mp3';
import { galleryModal } from '@states';
import { loader, pagetrans } from '@states';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------------
import { Jacket, Sweater, Photo } from './styles';

// Component
// ------------
const PhotoList = ({ data, stacked }) => {
    // NOTE • Breakpoints
	const bp = useBreakpoint();

    // NOTE • Sounds
    const [play] = useSound(Click, { volume: 1 });

    // NOTE • Group data into tmeplates
    const group = (items, n) => items.reduce((acc, x, i) => {
        const idx = Math.floor(i / n);
        acc[idx] = [...(acc[idx] || []), x];
        return acc;
    }, []);

    const activateGallery = action((indexVal) => {
        galleryModal.isActive = true;
        galleryModal.activeSlide = indexVal;
        play();
    });

    return (
        <Jacket isVisible={loader.isComplete && pagetrans.isDefault} pad>
            {group(data, 5).map((children, i) => (
                <Sweater key={i} stacked={stacked} isExpanded>
                    {children.map((x, i) => {
                        let comparedIndex;

                        const indexChecker = (i) => {
                            if (i === 0) return "one";
                            if (i === 1) return "two";
                            if (i === 2) return "three";
                            if (i === 3) return "four";
                            if (i === 4) return "five";
                        }

                        data.forEach((item, i) => {
                            if (item.data === x.data) {
                                comparedIndex = i + 1;
                            }
                        });

                        const handleGalleryModal = () => {
                            activateGallery(comparedIndex);
                        }
                        
                        return (
                            <React.Fragment key={i}>
                                {bp.large ? (
                                    <Photo id={`image-` + comparedIndex} className={`ch photo-` + (i + 1)} gridArea={indexChecker(i)} stacked={stacked} onClick={stacked ? null : handleGalleryModal}>
                                        <DesktopImage ar={x.res.aspectRatio} image={x.data} alt={x.alt} />
                                    </Photo>
                                ) : (
                                    <Photo id={`image-` + comparedIndex} className={`ch photo-` + (i + 1)} gridArea={indexChecker(i)} stacked={stacked}>
                                        <MobileImage isMobile ar={x.res.aspectRatio} image={x.data} alt={x.alt} />
                                    </Photo>
                                )}
                            </React.Fragment>
                        )
                    })}
                </Sweater>
            ))}
        </Jacket>
    );
}

export default observer(PhotoList);