// Imports
// ------------
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------------
import { Jacket, Image } from './styles';

// Component
// ------------
const GalleryCard = ({ image }) => {
    return (
        <Jacket aspectRatio={image.res.aspectRatio}>
            <Image aspectRatio={image.res.aspectRatio}>
                <GatsbyImage image={image.data} alt={image.alt} />
            </Image>
        </Jacket>
    );
}

export default GalleryCard;