// Imports
// ------
import React from 'react';
import Seo from '@parts/Seo';
import SmoothScroll from '@parts/SmoothScroll';
import Hero from '@parts/Hero';
import PhotoList from '@parts/Gallery/Single/PhotoList';
import GalleryCarousel  from '@parts/Gallery/Single/Carousel';
import DesktopNext from '@parts/Gallery/DesktopNext';
import MobileNext from '@parts/Gallery/MobileNext';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Component
// ------
const Gallery = ({ pageContext, data }) => {
	// NOTE • Breakpoints
	const bp = useBreakpoint();

	// NOTE • Pagination
	// ------
	const { nextPage, firstPage } = pageContext;
    const next = nextPage.node;
    const nextURL = nextPage.node ? ('/gallery/' + next.slug + '/') : ('/gallery/' + firstPage.node.slug + '/');
    // const nextImage = next ? next.featuredImage : firstPage.node.featuredImage;
    const nextTitle = next ? next.title : firstPage.node.title;
    const nextSubtitle = next ? next.subtitle : firstPage.node.subtitle;
	const nextImages = next ? next.imageList : firstPage.node.imageList;

	return (
		<>
			<Seo meta={data.page.seoMeta} />
			<GalleryCarousel data={data.page.imageList} />
			
			<SmoothScroll>
				<Hero
					title={data.page.title}
					sub={data.page.subtitle}
				/>

				<PhotoList data={data.page.imageList} stacked={data.page.stackedImages} />

				<div key="nextPage">
					{bp.large ? (
						<DesktopNext title={nextTitle} subtitle={nextSubtitle} slug={nextURL} images={nextImages} />
					) : (
						<MobileNext title={nextTitle} subtitle={nextSubtitle} slug={nextURL} images={nextImages} />
					)}
				</div>
			</SmoothScroll>
		</>
	);
}

export default Gallery;


// GraphQL
// ------
export const query = graphql`
    query GalleryQuery($slug: String!) {
        page: datoCmsGallery(slug: { eq: $slug }) {
			title
            subtitle

			imageList {
				width
				height
				alt
				res: resolutions {
					aspectRatio
				}
				data: gatsbyImageData(
					width: 600
					placeholder: BLURRED
					layout: FULL_WIDTH
				)
			}

			stackedImages

			seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
		}
	}
`;