// Imports
// ------------
import React from 'react';
import MarqueeHeadline from '@parts/MarqueeHeadline';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------------
import { JacketMobile, Image, Subtext, Title, Next, Line } from './styles';

// Component
// ------------
const MobileNext = ({ slug, title, subtitle, images }) => {
    return (
        <JacketMobile to={slug}>
            <Image>
                <GatsbyImage image={images[0].data} alt={images[0].alt} />
            </Image>

            <Subtext>
                <MarqueeHeadline data={subtitle + subtitle} />
            </Subtext>

            <Title>{title} <span>{images.length}</span></Title>

            <Next>
                <span>Next up</span>
                <Line />
            </Next>
        </JacketMobile>
    );
}

export default MobileNext;