// Imports
// ------------
import styled, { css } from 'styled-components';
// import { breakup } from '@tackl';

// Exports
// ------------
export const Jacket = styled.div((props) => css`
    position: relative;
    overflow: hidden;

    width: 100%;
    height: 100%;
    padding: 8rem;

    display: flex;
    justify-content: center;
    align-items: center;
`);

export const Image = styled.div((props) => css`
    display: flex;
    align-items: center;

    max-width: 100%;
    height: 100%;
    aspect-ratio: ${props.aspectRatio};

    .gatsby-image-wrapper {
        width: 100%;
        box-shadow: 0rem 2.4rem 8rem 0rem ${props.theme.black};
    }
    
    img {
        aspect-ratio: ${props.aspectRatio};
    }
`);
