// Imports
// ------------
import styled, { css } from 'styled-components';

// Exports
// ------------
export const Jacket = styled.div(props => css`
    position: relative;
    overflow: hidden;
    aspect-ratio: ${props.aspectRatio};
`);

export const BaseImage = styled.div(
    (p) => css`
        position: absolute;
        z-index: 0;
        top: 0; left: 0; right: 0; bottom: 0;
        will-change: transform;

        .gatsby-image-wrapper {
            position: absolute;
            top: 0; left: 0;
            height: 100%; width: 100%;
            object-fit: cover;
        }
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    `
);
