// Imports
// ------------
import React, { useRef, useEffect } from 'react';
import gsap from "gsap";
import Scrollbar from 'smooth-scrollbar';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------------
import { Jacket, BaseImage } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const SingleImageDesktop = ({ ar, image, alt }) => {
    // NOTE • Refs
    const target = useRef();
    const trigger = useRef();

    //  NOTE • OnMount: Scroll Animation
    useEffect(() => {
        // NOTE • Variables for scroller
        const scroller = document.querySelector('#scroller');
        const bodyScrollBar = Scrollbar.init(scroller, { delegateTo: document, alwaysShowTracks: false });

        // NOTE • Proxy for scroller to pick up smooth scroll
        ScrollTrigger.scrollerProxy(scroller, {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });
        
        // NOTE • Animation
        gsap.to(target.current, {
            ease: "none",
            y: `-12%`,
            // scale: 1,
            scrollTrigger: {
                scroller: scroller,
                trigger: trigger.current,
                start: "0% 100%",
                end: "100% 0%",
                scrub: 0.2, // Lower the value, lazier it is
                markers: false,
            },
        });

        // NOTE • Move markers to correct position
        if (document.querySelector(".gsap-marker-scroller-start")) {
            const markers = gsap.utils.toArray('[class *= "gsap-marker"]');

            bodyScrollBar.addListener(({ offset }) => {
                gsap.set(markers, { marginTop: -offset.y });
            });
        }

        // NOTE • Update positions & timing
        bodyScrollBar.addListener(ScrollTrigger.update);
    });

    return (
        <Jacket ref={trigger} aspectRatio={ar}>
            <BaseImage ref={target}>
                <GatsbyImage image={image} alt={alt} />
            </BaseImage>
        </Jacket>
    );
}

export default SingleImageDesktop;