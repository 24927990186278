// Imports
// ------------
import React, { useEffect, useState } from 'react';
import MarqueeHeadline from '@parts/MarqueeHeadline';
import ClickNHold from 'react-click-n-hold';
import { GatsbyImage } from 'gatsby-plugin-image';
import { observer } from 'mobx-react-lite';
import { useTransitionStore } from 'gatsby-plugin-transitions';
import { pagetrans, galleryCursor } from '@states';
import { action } from 'mobx';

// Styles
// ------------
import { Jacket, Image, Subtext, Title, Next, Line } from './styles';

// Component
// ------------
const DesktopNext = ({ slug, title, subtitle, images }) => {
    // Actions
    const setTrue = action(() => {
        pagetrans.isGallery = true;
        pagetrans.galleryImage = images[0].data;
        pagetrans.galleryImageAlt = images[0].alt;
    });

    // States
    const [holding, setHolding] = useState(false);
    const [finished, setFinished] = useState(false);

    // Transition Store
    const [, dispatch] = useTransitionStore();

    // Click n Hold functions
    const start = (e) => {
        setHolding(true);
    }

    const end = (e, enough) => {
        if(enough) {
            setHolding(true);
            setFinished(true);
        } else {
            setHolding(false);
            setFinished(false);
        }
    }

    const clickHolding = (e) => {
        // console.log(e)
        galleryCursor.needsRelease = true;
    }

    useEffect(() => {
        if(finished) {
            // Return the new transition
            dispatch({
                type: "NAVIGATE",
                to: slug,
                leave: {
                    opacity: 0,
                    config: {
                        duration: 1000,
                        clamp: true,
                    },
                    onStart: () => {
                        setTrue();
                    }
                },
            });
        }
    }, [finished, dispatch, slug]);

    return (
        <Jacket>
            <ClickNHold
                time={2} // Time to keep pressing. Default is 2
                onStart={start} // Start callback
                onClickNHold={clickHolding} //Timeout callback
                onEnd={end}
                className="hold holder"
            >
                <Image>
                    <GatsbyImage image={images[0].data} alt={images[0].alt} />
                </Image>
            </ClickNHold>

            <Subtext>
                <MarqueeHeadline data={subtitle + subtitle} />
            </Subtext>

            <Title>{title} <span>{images.length}</span></Title>

            <Next>
                <span>Next up</span>
                <Line isHolding={holding} />
            </Next>
        </Jacket>
    );
}

export default observer(DesktopNext);